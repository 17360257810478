<template>
  <div>
    <file-upload
      class="drag-drop"
      ref="upload"
      :value="value"
      :post-action="postRoute"
      :extensions="extensions"
      :accept="accept"
      :headers="headers"
      :input-id="suffix"
      :drop="true"
      :multiple="multiple"
      @input-file="inputFile"
      @input-filter="inputFilter"
    >
      <span>Click or Drag & Drop File Here</span>
    </file-upload>
    <b-progress
      v-if="uploadProgress && uploadProgress < 100"
      class="my-3"
      :value="uploadProgress"
      show-progress
      animated
    />
  </div>
</template>

<script>
import VueUploadComponent from 'vue-upload-component'
import shortid from 'shortid'

export default {
  components: {
    'file-upload': VueUploadComponent
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    postRoute: {
      type: String,
      default: '/api/v2/images'
    },
    fileTypes: {
      type: Array,
      default: () => ['image']
    },
    value: Array
  },
  data () {
    return {
      suffix: shortid.generate(),
      imageExtensions: 'jpg,gif,png,jpeg',
      imageMime: 'image/png,image/jpg,image/jpeg,image/gif',
      videoExtensions: 'mp4',
      videoMime: 'video/mp4',
      uploadProgress: null
    }
  },
  methods: {
    inputFile (newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.response) {
          this.$emit('input', newFile.response)
          this.uploadProgress = null
        }
        // if (newFile.xhr) {
        //   console.log('response', newFile)
        // }
      }
      if (newFile && newFile.progress) {
        this.uploadProgress = parseInt(newFile.progress) // eslint-disable-line
      }
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        const ext = this.extensions.split(',')
        if (ext.indexOf(newFile.name.split('.').pop()) === -1) {
          // error msg for not valid
          return prevent()
        }
      }
      return ''
    }
  },
  computed: {
    extensions () {
      const { fileTypes } = this
      const extensions = []
      if (fileTypes.length) {
        fileTypes.forEach((t) => this[`${t}Extensions`] && extensions.push(this[`${t}Extensions`]))
      }
      return extensions.join()
    },
    accept () {
      const { fileTypes } = this
      const accept = []
      if (fileTypes.length) {
        fileTypes.forEach((t) => this[`${t}Mime`] && accept.push(this[`${t}Mime`]))
      }
      return accept.join()
    },
    headers () {
      return { Authorization: `Bearer ${this.$root.token}` }
    }
  }
}
</script>

<style scoped>
.drag-drop {
  width: 100%;
  height: 150px;
  border: 0.75em;
  border-style: dashed solid;
  display: flex!important;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}
</style>
